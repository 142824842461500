import React from "react";
import { Provider } from "react-redux";

import CustomLayout from "./src/wrapPageElement";
import store from "./src/store/store";

import "./src/global.scss";

export const wrapPageElement = CustomLayout;

export const wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>;
};
