import { Link } from "gatsby";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectHoveredProject, setHoveredProject } from "../store/store";
import clsx from "clsx";
import { useDevice, useDeviceListner } from "../utils/hooks";
import { PHOTO_URLS, getProjectCoverURL } from "../utils/urls";

export interface Position {
  x: number;
  y: number;
}

interface ScrollablePageProps {
  position: Position;
  className?: string;
  children?: React.ReactNode;
  onScroll?: () => {};
}

export const PROJECTS = [
  "evan",
  "diana",
  "moti",
  "rimedio",
  "cloud",
  "rituale",
];

const BACKGROUND_COLORS = {
  evan: "#AE7951",
  diana: "#7F8C78",
  moti: "#B3C7CA",
  rimedio: "#8A6E73",
  cloud: "#B0C7E0",
  rituale: "#AFA3D7",
};

export default function ScrollablePage({
  className,
  position,
  children,
  onScroll,
}: ScrollablePageProps) {
  const hoveredProject = useSelector(selectHoveredProject);
  const dispatch = useDispatch();

  const projectPage = position.x === 1;

  const handleHPLinkClick = () => {
    dispatch(setHoveredProject(null));
  };

  const handleScroll = () => {
    onScroll && onScroll();
  };

  useDeviceListner();

  const device = useDevice();

  return (
    <>
      <div
        className="project-atf-background"
        style={{ backgroundColor: BACKGROUND_COLORS[hoveredProject] }}
      ></div>
      <div className="background-container">
        <div
          className="background-parallax"
          style={{
            transform: `translateY(${100 * -position.y}vh) translateX(${
              -100 * position.x
            }vw)`,
          }}
        >
          {/* <img
            className="background-top-left"
            src={PHOTO_URLS.BACKGROUND_TOP_LEFT}
          />
          <img
            className="background-bottom-right"
            src={PHOTO_URLS.BACKGROUND_BOTTOM_RIGHT}
          /> */}
          <img
            className="background-center"
            src={PHOTO_URLS.BACKGROUND}
          />
        </div>
        {/* <div>
          {hoveredProject &&
            device === "desktop" &&
            PROJECTS.filter(
              (_, i) => PROJECTS.indexOf(hoveredProject) >= i
            ).map((projectName) => (
              <img
                key={projectName}
                className={clsx(
                  "project-cover",
                  projectPage ? "out-of-view" : "in-view"
                )}
                src={getProjectCoverURL(projectName)}
              />
            ))}
        </div> */}
      </div>
      <main className={clsx("page-content", className)} onScroll={handleScroll}>
        <Link className="site-logo" onClick={handleHPLinkClick} to={"/"}>
          <h1>
            Veronica
            <br />
            <span className="last-name">Giannella</span>
          </h1>
        </Link>
        {children}
      </main>
    </>
  );
}
