import { useEffect } from "react";
import { selectDevice, setDevice } from "../store/store";
import { useDispatch, useSelector } from "react-redux";

import { version } from "../../package.json";

type Device = "desktop" | "mobile";

export const useDeviceListner = () => {
  const dispatch = useDispatch();

  function detectDevice() {
    const width = window.innerWidth;
    const device: Device = width < 768 ? "mobile" : "desktop";
    dispatch(setDevice(device));
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", detectDevice);
      detectDevice();
      return () => window.removeEventListener("resize", detectDevice);
    }
  }, []);
};

export const useDevice = () => useSelector(selectDevice);

export const useLogVersion = () => {
  useEffect(() => {
    console.log("[www.veronicagiannella.it] Version", version);
  }, []);
};
