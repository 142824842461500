const MEDIA_BASE_URL = process.env.GATSBY_MEDIA_BASE_URL || "/media";

export const PHOTO_URLS = {
  BACKGROUND_CENTER: `${MEDIA_BASE_URL}/backgrounds/sfondo_centro.png`,
  BACKGROUND_TOP_LEFT: `${MEDIA_BASE_URL}/backgrounds/sfondo_alto_sx.png`,
  BACKGROUND_BOTTOM_RIGHT: `${MEDIA_BASE_URL}/backgrounds/sfondo_basso_dx.png`,
  BACKGROUND: `${MEDIA_BASE_URL}/backgrounds/faccia.gif`,
};

export function getProjectCoverURL(projectName: string) {
  return `${MEDIA_BASE_URL}/${projectName}/0_${projectName}.jpg`;
}

export function getPhotoURL(
  projectName: string,
  photoName: string,
  extension = "jpg"
) {
  return `${MEDIA_BASE_URL}/${projectName}/${photoName}.${extension}`;
}
